import { ChannelsInterface } from './channelsInterface';

export const useChannelsStore = (): ChannelsInterface => ({
  channels: {
    knowledgeBase: false,
    chat: true,
    facebookChat: true,
    audio: true,
    video: true,
    form: false,
    meetingSchedule: false,
    whatsApp: true,
    callBack: {
      active: true,
      immediateCallback: true,
      scheduledCallback: true,
    },
  },
});
