import { GeneralInterface } from './GeneralInterface';

export const useGeneralStore = (): GeneralInterface => ({
  general: {
    about: {
      active: true,
      lastEdit: '',
      created: '',
      priority: 'A',
    },
    name: {
      widgetName: '',
      oldWidgetName: '',
      widgetDescription: '',
    },
    language: {
      selectedLanguageValue: 'en',
    },
    additionalSettings: {
      singleChannelMode: false,
      chatTranscript: false,
      defaultVisitorBlur: false,
      defaultAgentBlur: false,
      chooseWebMessengerDeployment: {
        active: false,
        deploymentId: null,
      },
      channelsOrder: ['knowledgeBase', 'chat', 'whatsApp', 'facebookChat', 'callUs', 'video', 'callback', 'form', 'meetingSchedule'],
    },
    aiHeadsUpTexts: false,
  },
});
